import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { NavLink } from 'ion-navchevron'
import logo from '../../static/general/glamour.svg'
import { Articles } from 'ion-article-cmp'
import NoImage from '../../static/no-image.png'
import { ImageOrNoImage } from 'ion-image'
import { Button } from 'semantic-ui-react'
import { MenuIco } from 'ion-icon'
import { DesktopTablet, Mobile } from 'ion-media'
import useHasMounted from '../useHasMounted'

const MenuTab = (props) => (
  <Articles
    section={props.section} pageSize={4} component={({ articles, noImage }) => {
      return articles.map((article) => (
        <article key={article.contentKey}>
          <Link to={'/' + article.getCanonicalUri()}>
            <ImageOrNoImage shape='square' width={320} image={article.image} alt={article.headline} noImage={noImage} />
            <h3>{article.headline}</h3>
          </Link>
        </article>
      ))
    }} noImage={NoImage} notFound={() => (<div>Not Found</div>)} {...props}
  />
)

const Header = (props) => {
  const hasMounted = useHasMounted()
  return (
    <header role='banner' className={props.useSmall ? 'reduced-header' : ''}>
      <a href='#main-content' className='show-on-focus' tabIndex='0'>
        Skip to content
      </a>
      <div className='wrapper'>
        <Mobile>
          <Button tabIndex='2' className='menu-btn' onClick={() => props.toggleMenu()}><MenuIco width='24' height='24' /></Button>
        </Mobile>
        <Link to='/' className='logo'>
          <img src={logo} alt='Glamour South Africa' />
        </Link>
      </div>
      {hasMounted &&
        <DesktopTablet>
          <div className='wrapper'>
            <nav>
              <ul>
                <li className='drop-dwn'>
                  <NavLink to='/fashion'>Fashion</NavLink>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/fashion/trends'>Fashion Trends</Link>
                      <MenuTab section='fashion/trends' {...props} />
                    </li>
                    <li>
                      <Link to='/fashion/celebrity-style'>Celebrity Style</Link>
                      <MenuTab section='fashion/celebrity-style' {...props} />
                    </li>
                    <li>
                      <Link to='/fashion/accessories'>Accessories</Link>
                      <MenuTab section='fashion/accessories' {...props} />
                    </li>
                    <li>
                      <Link to='/fashion/style-diary'>Style Diary</Link>
                      <MenuTab section='fashion/style-diary' {...props} />
                    </li>
                  </ul>
                </li>
                <li className='drop-dwn'>
                  <NavLink to='/beauty'>Beauty</NavLink>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/beauty/hair'>Hair</Link>
                      <MenuTab section='beauty/hair' {...props} />
                    </li>
                    <li>
                      <Link to='/beauty/trends'>Beauty Trends</Link>
                      <MenuTab section='beauty/trends' {...props} />
                    </li>
                    <li>
                      <Link to='/beauty/makeup'>Makeup</Link>
                      <MenuTab section='beauty/makeup' {...props} />
                    </li>
                    <li>
                      <Link to='/beauty/skincare'>Skin Care</Link>
                      <MenuTab section='beauty/skincare' {...props} />
                    </li>
                    <li>
                      <Link to='/beauty/nails'>Nails</Link>
                      <MenuTab section='beauty/nails' {...props} />
                    </li>
                    <li>
                      <Link to='/beauty/beauty-school'>Beauty School</Link>
                      <MenuTab section='beauty/beauty-school' {...props} />
                    </li>
                    <li>
                      <Link to='/beauty/beauty-awards'>Beauty Awards</Link>
                      <MenuTab section='beauty/beauty-awards' {...props} />
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/celebrity-news'>Celebs</NavLink>
                </li>
                <li className='drop-dwn'>
                  <NavLink to='/lifestyle'>Lifestyle</NavLink>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/lifestyle/careers'>Careers</Link>
                      <MenuTab section='lifestyle/careers' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/food-drink'>Food & Drink</Link>
                      <MenuTab section='lifestyle/food-drink' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/travel'>Travel</Link>
                      <MenuTab section='lifestyle/travel' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/weddings'>Weddings</Link>
                      <MenuTab section='lifestyle/weddings' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/man-candy'>Man Candy</Link>
                      <MenuTab section='lifestyle/man-candy' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/glamour-guides'>Glam Guides</Link>
                      <MenuTab section='lifestyle/glamour-guides' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/glam-book-club'>GLAM Book Club</Link>
                      <MenuTab section='lifestyle/glam-book-club' {...props} />
                    </li>
                    <li>
                      <Link to='/lifestyle/women-in-charge'>Women in Charge</Link>
                      <MenuTab section='lifestyle/women-in-charge' {...props} />
                    </li>
                  </ul>
                </li>
                <li className='drop-dwn'>
                  <NavLink to='/wellness'>Wellness</NavLink>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/wellness/fitness-exercise'>Fitness &amp; Exercise</Link>
                      <MenuTab section='wellness/fitness-exercise' {...props} />
                    </li>
                    <li>
                      <Link to='/wellness/love-relationships'>Love &amp; Relationships</Link>
                      <MenuTab section='wellness/love-relationships' {...props} />
                    </li>
                    <li>
                      <Link to='/wellness/mindfulness'>Mindfulness</Link>
                      <MenuTab section='wellness/mindfulness' {...props} />
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/events'>Events</NavLink>
                </li>
                <li>
                  <NavLink to='/win'>Win</NavLink>
                </li>
                <li className='drop-dwn'>
                  <NavLink to='/more'>More</NavLink>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/more/gift-guide'>Gift Guide</Link>
                      <MenuTab section='more/gift-guide' {...props} />
                    </li>
                    <li>
                      <Link to='/more/glam-x-skip'>GLAM x SKIP</Link>
                      <MenuTab section='more/glam-x-skip' {...props} />
                    </li>
                    <li>
                      <Link to='/more/glam-x-ciao-malfy'>GLAM x Ciao Malfy</Link>
                      <MenuTab section='more/glam-x-ciao-malfy' {...props} />
                    </li>
                    <li>
                      <Link to='/more/glam-x-mac'>GLAM x MAC</Link>
                      <MenuTab section='more/glam-x-mac' {...props} />
                    </li>
                    {/* <li>
                      <Link to='/more/sparkle-on-with-bernini'>Sparkle on with Bernini</Link>
                      <MenuTab section='more/sparkle-on-with-bernini' {...props} />
                    </li> */}
                    {/* <li>
                      <Link to='/more/glam-x-ariel'>GLAM x Ariel</Link>
                      <MenuTab section='more/glam-x-ariel' {...props} />
                    </li> */}
                    {/* <li>
                      <Link to='/more/glam-x-bulgari'>GLAM x Bulgari</Link>
                      <MenuTab section='more/glam-x-bulgari' {...props} />
                    </li> */}
                    <li>
                      <Link to='/more/glams-most-glam'>GLAM's Most GLAM</Link>
                      <MenuTab section='more/glams-most-glam' {...props} />
                    </li>
                    {/* <li>
                      <Link to='/more/style-your-smile'>#StyleYourSmile</Link>
                      <MenuTab section='more/style-your-smile' {...props} />
                    </li> */}
                    <li>
                      <Link to='/more/women-of-the-year'>Women of the Year</Link>
                      <MenuTab section='more/women-of-the-year' {...props} />
                    </li>
                    {/* <li>
                      <Link to='/more/how-do-i-look-sa'>How Do I Look? SA</Link>
                      <MenuTab section='more/how-do-i-look-sa' {...props} />
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </DesktopTablet>}
    </header>
  )
}

export default connect((state) => ({ articles: state.articles }))(Header)
