import React, { Component } from 'react'
import { fetchMagazineCover } from '../../store/magazineCover'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export class MagazineCover extends Component {
  constructor (props) {
    super(props)
    if (!this.props.magazineCover) {
      this.props.fetchMagazineCover()
    }
  }

  render () {
    return (
      <div className='on-sale-now'>
        <div>
          {this.props.magazineCover &&
            <img src={this.props.magazineCover.image} alt='magazine cover' />}
        </div>
        <div className='print-word'>
          <p className='print-digital'>PRINT OR DIGITAL?</p>
          <p className='never-miss'>Never miss out</p>
        </div>
        <div>
          <a href='https://www.zinio.com/za/publisher/publications/glamour-bonus-issue/43650' target='_blank' rel='noopener noreferrer' className='edition-subscribe digital'>Download your digital edition</a>
        </div>
        <div>
          <a href='https://4me.co.za/product/glamour/' target='_blank' rel='noopener noreferrer' className='edition-subscribe print'>I want a hard copy</a>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => (Object.assign({}, state.magazineCover))

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchMagazineCover
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MagazineCover)
